:root {
    --secondery-light: #fff;
    --secondery-dark: #232323;
    --main-light: #232323;
    --main-dark: #fff;
    --third-light:#f8f8f8;
    --third-dark:#383838;
  
  }
  
  html[data-theme='dark'] {
    background: var(--secondery-dark);
    --secondery: var(--secondery-dark);
    --main: var(--main-dark);
    --third: var(--third-dark);
  }
  
  html[data-theme='light'] {
    background: var(--secondery-light);
    --secondery: var(--secondery-light);
    --main: var(--main-light);
    --third: var(--third-light);
  }
  