.ProjectsCardContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}
.ProjectsSingleCard{
    padding-top: clamp(12.5px,2vw,30px);
    padding-bottom:clamp(12.5px,2vw,30px) ;
    padding-left:clamp(19px,2vw,45px) ;
    padding-right:clamp(19px,2vw,45px) ;
    border-radius: 12px;
    color: var(--main);
    transition: ease-in-out 250ms;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    background-color: var(--third);
    // height: 40vh;
    justify-content: space-between;
    width: 25%;
    position: relative;
    overflow: hidden;
    min-width: 250px;
}
.ProjectSingleCardTitle{
    font-size: clamp(12px,2vw,21px);
    font-weight: 600;
    color: var(--main);
    width: 50%;
}
.ProjectsSingleCardButton{
    padding-top: clamp(7px,2vw,17.5px);
    padding-bottom: clamp(7px,2vw,17.5px);
    padding-left: clamp(14px,2vw,35px);
    padding-right: clamp(14px,2vw,35px);
    font-size: clamp(60%, 1vw, 95%);
}
.ProjectsSingleCardDescription{
    height: -webkit-fill-available;
    overflow: hidden;
    z-index: 2;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    gap: 5px;
}
.ProjectsSingleCard:hover{
    transform: scale(105%);
}
.ProjectsSingleCardSecondDescriptionUL{
    padding-inline-start: 25px;
}
