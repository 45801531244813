@import '../../styles/variables';

.NavBarContainer {
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 4vh 0 0 0;
    padding: 0 4vh 0vh 4vh;
    background-color: var(--secondery);
    z-index: 10;
  }

.isSticky{
    box-shadow: 0 10px 50px 0 rgba(74,144,226,.08);
    opacity: 0.98;
}
.NavBar{
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh;
}
.NavBarLogoContainer{
    display: flex;
    align-items: center;
}
.NavBarLogo{
    height: 8vh;
}
.V2ButtonContainer{
    display: flex;
    gap: 1vw;
}
.NavBarLogoLetter{
    fill:var(--main) ;
}


#hybrid {
    visibility: hidden;
  }
  
  #theme-toggle {
    visibility: hidden;
    position: absolute;
  }
  
  .container{
    padding: 0 1.5vw;
    display: flex;
    align-items: center;
  }

  #theme-toggle + label {
    content: '';
    display: inline-block;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  
  #theme-toggle:not(:checked) + label {
    // background-color: gold;
    background: linear-gradient(to bottom right, #f3ff48, #ffac76);
  }
  
  #theme-toggle:checked + label {
    background-color: transparent;
    box-shadow: inset -8px -8px 1px 1px #fff;
  }
  
  .intro-text {
    top: 180px;
    font-size: 1.6em;
    font-family: monospace;
  }
  
  [class*=text-] {
    font-size: 1.2em;
    font-weight: 600;
    transition: color 500ms ease-in;
  }
  .NavBarButtonContainer{
    display: flex;
    align-items: center;
  }

  .ButtonDiv{
    display: flex;
    align-items: center;
    gap: 7px;
    position: relative;
  }

  .ExpandSVG{
    height: 20px;
    width: 20px;
    fill: var(--main);
  }

  .ButtonDiv .V1Button{
    padding: 0;
  }

  .ExpandMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color:transparent;
    padding: 8px 0;
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }
  .ExpandMenu .V1Button{
    background-color: var(--third);
  }
  .ButtonDiv{
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  .ExpandMenuButtonsContainer{
    background-color: var(--third);
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    border-radius: 12px
  }

  .ExpandMenuButtonsContainer .V1Button:hover{
    color: #96e6a1;
  }

  .ExpandMenuButtonsContainer .V1Button{
    font-size:13px;
    padding: 7px 7px;
  }

  .Up{
    transform: rotate(180deg);
  }

  @media(max-height:700px) and (min-width:700px){
    #theme-toggle + label{
      height: clamp(15px, 3.5vh, 25px);
      width: clamp(15px, 3.5vh, 25px);
    }
    #theme-toggle:checked + label {
      box-shadow: inset -5px -5px 1px 1px #fff;
    }
  }

  @media(max-width:700px){
    .NavBarButtonContainer{
      display: none;
      visibility: hidden;
    }
    .NavBar{
      padding: 2vh 2vw;
    }
    .NavBarContainer{
      padding: 0 4vw 0vh 4vw;
    }
    .V2ButtonContainer{
      gap: 2vw;
    }
    #theme-toggle + label{
      height: clamp(15px, 3.5vw, 25px);
      width: clamp(15px, 3.5vw, 25px);
    }
    #theme-toggle:checked + label {
      box-shadow: inset -5px -5px 1px 1px #fff;
    }
  }