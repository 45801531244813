@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ContactMe {
  color: var(--main);
  transition: ease-in-out 250ms;
  margin: 0.25vh 1vw;
  border: 1.5px solid var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  background: linear-gradient(to right, #d4fc79, #96e6a1);
  background-size: 400% 400%; /* Increase the background size to create more vibrant effect */
  animation: gradientAnimation 5s ease infinite; /* Reduce the animation duration */
}

.ContactMeFirstChild {
  box-sizing: border-box;
  width: 45%;
  padding: 5% 3.5%;
  display: flex;
  flex-direction: column;
  gap: 6vh;
  background-color: var(--secondery);
}

.ContactMeSection {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
}

.ContactMeSecondChild {
  width: 55%;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ContactMeEmailButton {
  color: var(--main);
  text-decoration: underline;
  opacity: 0.9;
  font-size: clamp(10px, 1vw, 13px);
}

.ContactMeInputLabel {
  color: var(--main);
  opacity: 0.9;
  font-size: 11px;
  font-weight: 600;
}

.ContactMeThroughEmailContainer {
  display: flex;
  align-items: center;
}

.emailSpan {
  width: auto;
}

.ContactMeInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
}

.ContactMeInput {
  border: 0;
  padding: 2%;
  padding-left: 0;
  border-bottom: 1.5px solid var(--main);
  outline: none;
  color: var(--main);
  background: transparent;
  resize: none;
  font-size: clamp(10px, 2vw, 13px);
}

.ContactMeSubmitButton {
  padding-top: clamp(7px, 2vw, 17.5px);
  padding-bottom: clamp(7px, 2vw, 17.5px);
  padding-left: clamp(14px, 2vw, 35px);
  padding-right: clamp(14px, 2vw, 35px);
  font-size: clamp(60%, 1vw, 95%);
  font-weight: 600;
}

.ContactMeBlobs {
  position: absolute;
  width: 55%;
  top: 0;
  right: 0;
  bottom: 0;
}

.ContactMeFormSucessMessage {
  padding: 7%;
  margin: 7%;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 1000px) {
  .ContactMeSecondChild {
    width: 0;
    visibility: hidden;
    display: none;
  }
  .ContactMeFirstChild {
    width: 100%;
  }
}/*# sourceMappingURL=ContactMe.css.map */