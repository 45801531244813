.TitleAndSkillsContainer {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.ProjectSkillsContainer {
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
}

.ProjectContainer {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.DescriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.VideoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.VideoTabsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.MediaPlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  background-color: var(--third);
}

.Media {
  width: 70%;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main);
}

.VideoTabButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.VideoTabButtonActive {
  background-color: var(--third);
}

.ProjectsListItemLine {
  width: 15px;
  height: 1px;
  margin: 0 20px 0 0;
  background-color: var(--main);
}

@media (max-width: 700px) {
  .ProjectContainer {
    flex-direction: column;
    gap: 30px;
  }
  .ProjectSkillsContainer {
    width: 100%;
    max-width: none;
    justify-content: center;
  }
}/*# sourceMappingURL=Project.css.map */