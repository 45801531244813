@import '../../styles/variables';
.ExperinceCarousel{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 6vh;
}
.ExperinceCarouselTitleText{
    letter-spacing: 2.5px;
    font-weight: 300;
    font-size: 20px;
    opacity: 0.4;
    color: var(--main);
}
.AppBreweryDarkerElements{
    opacity: 0.65;
    fill: var(--third);
}
.AppBreweryWhiterElements{
    fill: var(--main);
    opacity: 0.65;
}
.ExperinceCarouselCarouselCardImage{
    fill: var(--main);
    opacity: 0.65;
    height: 12vh;
    max-width: 12vw;
    transition: all 0.3s ease-in-out;
}
// .ExperinceCarouselCarouselCardImage:hover{
//     opacity: 1;
// }
.ExperinceCarouselCarousel{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
.ExperinceCarouselCarouselCard:nth-child(2){
    transition-delay: 200ms;
}
.ExperinceCarouselCarouselCard:nth-child(3){
    transition-delay: 400ms;
}
.ExperinceCarouselCarouselCard:nth-child(4){
    transition-delay: 600ms;
}
.ExperinceCarouselCarouselCard:nth-child(5){
    transition-delay: 800ms;
}