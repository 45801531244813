:root {
  --secondery-light: #fff;
  --secondery-dark: #232323;
  --main-light: #232323;
  --main-dark: #fff;
  --third-light:#f8f8f8;
  --third-dark:#383838;
}

html[data-theme=dark] {
  background: var(--secondery-dark);
  --secondery: var(--secondery-dark);
  --main: var(--main-dark);
  --third: var(--third-dark);
}

html[data-theme=light] {
  background: var(--secondery-light);
  --secondery: var(--secondery-light);
  --main: var(--main-light);
  --third: var(--third-light);
}

.ExperinceCarousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
}

.ExperinceCarouselTitleText {
  letter-spacing: 2.5px;
  font-weight: 300;
  font-size: 20px;
  opacity: 0.4;
  color: var(--main);
}

.AppBreweryDarkerElements {
  opacity: 0.65;
  fill: var(--third);
}

.AppBreweryWhiterElements {
  fill: var(--main);
  opacity: 0.65;
}

.ExperinceCarouselCarouselCardImage {
  fill: var(--main);
  opacity: 0.65;
  height: 12vh;
  max-width: 12vw;
  transition: all 0.3s ease-in-out;
}

.ExperinceCarouselCarousel {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.ExperinceCarouselCarouselCard:nth-child(2) {
  transition-delay: 200ms;
}

.ExperinceCarouselCarouselCard:nth-child(3) {
  transition-delay: 400ms;
}

.ExperinceCarouselCarouselCard:nth-child(4) {
  transition-delay: 600ms;
}

.ExperinceCarouselCarouselCard:nth-child(5) {
  transition-delay: 800ms;
}/*# sourceMappingURL=ExperianceCarousel.css.map */