@import '../../styles/variables';

.Introduction{
    // background-color: gray;
    margin: 4vh 0;
    height: calc(100vh - 24vh);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}
.IntroductionHalfContainer{
    // width: 40%;
    width: clamp(40%,40vw,47.5%);
    height: -webkit-fill-available;
    // padding-top: 5vh;
    padding-top: 3vw;
    display: flex;
    flex-direction: column;
    gap: clamp(1%,2vw,5%);
    // justify-content: space-evenly;
    justify-content: space-around;
}
.IntroductionMainTitle{
    color: var(--main);
    // font-size: 55px;
    font-weight: 700;
    font-size: clamp(20px,3.5vw,55px);
}
.IntroductionSubTitle{
    color: var(--main);
    // font-size: 20px;
    // line-height: 35px;
    // line-height: clamp(15px,3vw,35px);
    line-height: clamp(20px, 3vw, 35px);
    font-size: clamp(10px,1.5vw,20px);;
}
.IntroductionButtonsContainer{
    display: flex;
    gap: 5%;
}
.IntroductionFooter{
    margin-top: 5vh;
    font-weight: 400;
    // font-size: 13px;
    font-size: clamp(10px,1vw,13px);
    opacity: 0.7;
    display: flex;
    align-items: center;
    gap: 4%;
    color: var(--main);
}
.IntroductionFooterLogo{
    // width: 35px;
    width: clamp(20px,3.5vw,35px);
    // height: 35px;
    height: clamp(20px,3.5vw,35px);
    fill: var(--main);
}
.IntroductionProfilePictureContainer{
    display: flex;
    justify-content: center;
    position: relative;
}
.IntroductionProfilePicture{
    max-height: 76vh;
    filter: invert(0.2);
    width: clamp(200px,50vw,600px);
    max-width: calc((76vh * 716) / 850);
    aspect-ratio: auto 716 / 850;
}
.IntroductionSecondHalfContainer{
    padding: 0;
    justify-content: flex-start;
}
.IntroductionProfile{
    position: relative;
}
.IntroductionProfilePictureBackground{
    position: absolute;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    // width: 500px;
    // height: 500px;
    aspect-ratio: 1/1;
    max-width: 500px;
    width: 80.5%;
    border-radius: 50%;
    right: 10%;
}
.IntroductionFooterButton{
    padding: 0.25vw 0.5vw;
}


@media(max-height:700px) and (min-width:700px){
    .IntroductionMainTitle{
        font-size: clamp(18px, 3.5vh, 35px);
    }
    .IntroductionHalfContainer{
        padding-top: 0;
    }
    .IntroductionSubTitle{
        font-size: clamp(10px, 2.50vh, 20px);
        line-height: clamp(20px, 3vh, 35px);
    }
    .IntroductionFooter{
        font-size: clamp(10px, 1vh, 13px);
    }
    .IntroductionFooterLogo{
        width: clamp(20px, 3.5vh, 35px);
        height: clamp(20px, 3.5vh, 35px);
    }
}
@media(max-width:700px){
    .IntroductionSecondHalfContainer{
        display: none;
        visibility: hidden;
    }
    .IntroductionHalfContainer{
        width: 80%;
    }
}