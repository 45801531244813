.SideBarContainer{
    position: fixed;
    top: 16vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    direction: rtl;
    width: calc( 100vw + 48px );
    transform: translateX(calc(100vw - 48px));
    transition: transform 0.3s ease; // The transition duration and timing function can be adjusted as needed
}
.SideBarOpen{
        transform: translateX(-48px)!important;
}
.SideBarComponent{
    position: absolute;
    top: 16vh;
}
.SideBar{
    background: var(--third);
    height: calc(84vh - 48px);
    width: 100vw;
}
.SideBarArrowSvg{
    fill: var(--main);
    background: linear-gradient(to bottom right, #d4fc79, #96e6a1);
    transform: rotate(180deg);
}
.SideBarReturnArrowSvg{
    fill: var(--main);
    background: linear-gradient(to bottom right, #d4fc79, #96e6a1);
}
.SideBarArrowsContainer{
    display: flex;
    justify-content: space-between;
}
.SideBarButtonContainer{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: calc(84vh - 48px);
    justify-content: space-around;
    direction: ltr;
    padding-left: 30px;
}
.SideBarExpandMenuButtonsContainer{
    background-color: var(--third);
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    border-radius: 12px
}

.SideBarButton{
    transition: transform 1s ease; // The transition duration and timing function can be adjusted as needed
    transform: translateX(0)!important;


}
.GoRight{
    transform: translateX(100vw)!important;
}
.GoRight svg{
    transform: rotate(180deg);
}


@media(min-width:700px){
    .SideBarContainer{
        visibility: hidden;
        display: none;
    }
}