@import 'variables';

*{
    font-family: 'Sora', sans-serif;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
.Main{
    display: flex;
    flex-direction: column;
    position: relative;
}
.Layout{
    width: 100%;
    // max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.V1Button{
    color: var(--main);
    text-decoration: none;
    padding: 0.5vw 1vw;
    border-radius: 12px;
    transition: ease-in-out 250ms;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none; /* For Firefox */
    -webkit-user-select: none; /* For Chrome, Safari, and Opera */
    -ms-user-select: none; /* For Internet Explorer/Edge */
}
.V1Button:hover{
    background-color: var(--third);
}
.V2Button{
    color: var(--main);
    transition: ease-in-out 250ms;
    text-decoration: none;
    padding: 0.5vw 1vw;
    border: 1px solid var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.MainContent{
     margin: 0 6vh;
     overflow: hidden;
}
.V2Button:hover{
    -webkit-transition: background-image 10s ease-in-out;
    transition: background-image 10s ease-in-out;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    color: var(--secondery);
    border: 1px solid var(--secondery);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.4) 0px 10px 10px -5px;
}

.V4Button{
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 18px;
    color: var(--main);
    text-decoration: none;
    padding: 1.5vw 3vw;
    padding-top: clamp(10px,2vw,25px);
    padding-bottom: clamp(10px,2vw,25px);
    padding-left:clamp(15px,2vw,50px) ;
    padding-right:clamp(15px,2vw,50px) ;
    border-radius: 12px;
    transition: ease-in-out 250ms;
    font-size: clamp(70%,1vw,150%);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.V4Button:hover{
    background-color: var(--third);
}


.V5Button {
  background: linear-gradient(to bottom right, #d4fc79,  #96e6a1);
  border: 0;
  justify-content: center;
  color: var(--secondery);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  outline: transparent;
  padding: 1.5vw 3vw;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: clamp(70%,1vw,150%);
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.V5Button:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(213, 252, 121, 0.187), .125rem .125rem 1rem rgba(150, 230, 161, 0.17);
}

.V5Button:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(213, 252, 121, 0.242), .125rem .125rem 1rem rgba(150, 230, 161, 0.219);
}
.Button{
    background: transparent;
    border: 0;
    cursor: pointer;
}
.ObserveEntity{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}
.RightObserveEntity{
    transform: translateX(+100%);
}
.LeftObserveEntity{
    transform: translateX(-100%);
}
.intersecting{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}
.commonTitle{
    letter-spacing: 2.5px;
    font-weight: 800;
    font-size: clamp(13px,2vw,30px);
    opacity: 0.4;
    color: var(--main);
    text-align: center;
}
.commonSeconderyTitle{
    font-weight: 700;
    font-size: clamp(14px,2.5vw,32px);;
    color: var(--main);
}
.commonThirdlyTitle{
    font-weight: 200;
    font-size: 17px;
    letter-spacing: 3.5px;
    text-decoration: underline;
    text-underline-offset: 5px;
    color: var(--main);
}
footer span{
    text-align: center;
}
.commonGreenBar{
    width: 50%;
    height: 2px;
    background: linear-gradient(to bottom right, #d4fc79, #96e6a1);
}
.commonSectionContainer{
    display: flex;
    align-items: center;
    gap: 4vh;
    flex-direction: column;
}

.commonDescription{
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.commonText{
    font-weight: 400;
    font-size: clamp(10px, 1vw, 14px);
    opacity: 0.7;
    display: flex;
    align-items: center;
    color: var(--main);
    letter-spacing: 0.5px;
    word-spacing: 3px;
    line-height: clamp(22px, 1vw, 26px);
}
.commonSeconderyText{
    font-weight: 400;
    font-size: clamp(8px, 1vw, 11px);
    opacity: 0.7;
    display: flex;
    align-items: center;
    gap: 4%;
    color: var(--main);
    width: 100%;
    overflow-wrap: anywhere;
}
.li{
    display: list-item;
    margin-bottom: 5px;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background: var(--secondery); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: #888; 
    background: linear-gradient(to bottom right, #d4fc79, #96e6a1);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
@media(max-height:700px) and (min-width:700px){
    .V5Button{
    padding: 2vh 5vh;
    font-size: clamp(70%, 1vh, 150%);
    }
    .V4Button{
    padding: 2vh 5vh;
    font-size: clamp(70%, 1vh, 150%);
    }
    .V1Button{
        font-size: clamp(10px, 2.5vh, 15px);
    }
    .V2Button{
        font-size: clamp(10px, 2.5vh, 15px); 
    }
}
@media(max-width:700px){
    .MainContent{
        margin-left:clamp(0px,7vw,6vh);
        margin-right: clamp(0px,7vw,6vh);
    }
    .V2Button{
        font-size: clamp(10px, 2.5vw, 15px); 
    }
    .commonDescription{
        width: 85%;
    }
}