.SkillsCard {
  padding: 10px 18px;
  border-radius: 12px;
  color: var(--main);
  transition: ease-in-out 250ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: clamp(2px, 2.2vw, 15px);
  align-items: center;
  background-color: var(--third);
}

.SkillsCard svg {
  height: clamp(16px, 3vw, 55px);
}

.SkillsCardType {
  color: var(--third);
  padding-top: clamp(0.5px, 0.5vw, 5px);
  padding-bottom: clamp(0.5px, 0.5vw, 5px);
  padding-left: clamp(1px, 1vw, 10px);
  padding-right: clamp(1px, 1vw, 10px);
  font-size: clamp(6px, 1vw, 13px);
  background-color: var(--main);
  opacity: 0.8;
}

.SkillsCardName {
  font-size: clamp(8px, 1.5vw, 18px);
  font-weight: 300;
  letter-spacing: clamp(0.5px, 0.4vw, 2.5px);
  color: var(--main);
}

.Skills {
  display: flex;
  flex-wrap: wrap;
  gap: 3.5vw;
  align-items: center;
  justify-content: center;
}

.SkillsCardIcon {
  height: 64px;
}

.svg-gradient {
  fill: url(#gradient);
}

.AngularLetterSvg {
  fill: var(--third);
}

.SkillsSelect {
  width: 13%;
  min-width: 150px;
}/*# sourceMappingURL=Skills.css.map */