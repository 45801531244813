:root {
  --secondery-light: #fff;
  --secondery-dark: #232323;
  --main-light: #232323;
  --main-dark: #fff;
  --third-light:#f8f8f8;
  --third-dark:#383838;
}

html[data-theme=dark] {
  background: var(--secondery-dark);
  --secondery: var(--secondery-dark);
  --main: var(--main-dark);
  --third: var(--third-dark);
}

html[data-theme=light] {
  background: var(--secondery-light);
  --secondery: var(--secondery-light);
  --main: var(--main-light);
  --third: var(--third-light);
}

.Introduction {
  margin: 4vh 0;
  height: 76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.IntroductionHalfContainer {
  width: clamp(40%, 40vw, 47.5%);
  height: -webkit-fill-available;
  padding-top: 3vw;
  display: flex;
  flex-direction: column;
  gap: clamp(1%, 2vw, 5%);
  justify-content: space-around;
}

.IntroductionMainTitle {
  color: var(--main);
  font-weight: 700;
  font-size: clamp(20px, 3.5vw, 55px);
}

.IntroductionSubTitle {
  color: var(--main);
  line-height: clamp(20px, 3vw, 35px);
  font-size: clamp(10px, 1.5vw, 20px);
}

.IntroductionButtonsContainer {
  display: flex;
  gap: 5%;
}

.IntroductionFooter {
  margin-top: 5vh;
  font-weight: 400;
  font-size: clamp(10px, 1vw, 13px);
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 4%;
  color: var(--main);
}

.IntroductionFooterLogo {
  width: clamp(20px, 3.5vw, 35px);
  height: clamp(20px, 3.5vw, 35px);
  fill: var(--main);
}

.IntroductionProfilePictureContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.IntroductionProfilePicture {
  max-height: 76vh;
  filter: invert(0.2);
  width: clamp(200px, 50vw, 600px);
  max-width: 64.0188235294vh;
  aspect-ratio: auto 716/850;
}

.IntroductionSecondHalfContainer {
  padding: 0;
  justify-content: flex-start;
}

.IntroductionProfile {
  position: relative;
}

.IntroductionProfilePictureBackground {
  position: absolute;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  aspect-ratio: 1/1;
  max-width: 500px;
  width: 80.5%;
  border-radius: 50%;
  right: 10%;
}

.IntroductionFooterButton {
  padding: 0.25vw 0.5vw;
}

@media (max-height: 700px) and (min-width: 700px) {
  .IntroductionMainTitle {
    font-size: clamp(18px, 3.5vh, 35px);
  }
  .IntroductionHalfContainer {
    padding-top: 0;
  }
  .IntroductionSubTitle {
    font-size: clamp(10px, 2.5vh, 20px);
    line-height: clamp(20px, 3vh, 35px);
  }
  .IntroductionFooter {
    font-size: clamp(10px, 1vh, 13px);
  }
  .IntroductionFooterLogo {
    width: clamp(20px, 3.5vh, 35px);
    height: clamp(20px, 3.5vh, 35px);
  }
}
@media (max-width: 700px) {
  .IntroductionSecondHalfContainer {
    display: none;
    visibility: hidden;
  }
  .IntroductionHalfContainer {
    width: 80%;
  }
}/*# sourceMappingURL=Introduction.css.map */